<template>
  <div class="candidate-profile">
    <div class="NonMedicalInformation">
      <div class="cardError">
        <div
          v-if="cardError"
          class="pb-1 cardError"
          :class="cardHasError ? 'card-error-msg' : ''"
        >
          {{ cardHasError }}
        </div>
      </div>
      <CCard :class="cardHasError ? 'card-has-error' : ''">
        <CCardBody>
          <CRow>
            <div class="col-md-12">
              <div class="align-items-center justify-content-between">
                <div class="mt-2 mr-2">
                  <CRow>
                    <CCol md="8">
                      <h5 class="pb-3 main-title">
                        Non-Medical Qualifications<span class="h6"
                          > ( obtained or studying for )[{{
                            candidateNonMedicalQuals.length || "0"
                          }}
                          record{{
                            candidateNonMedicalQuals.length > 1 ? "s" : ""
                          }}]</span
                        >
                      </h5>
                    </CCol>
                    <CCol md="4">
                      <!--<label class="col-lg-12 col-md-12"></label>-->
                      <div class="mr-2 d-flex justify-content-end">
                        <CButton
                          class="mr-2 btn-primary small"
                          style="height: 28px"
                          v-if="
                            !isNewNonMedicalQualification &&
                            !getMultiFormEdited['NonMedicalInfos'] &&
                            !edit_non_medical_id
                          "
                          @click="showNonMedicalQualificationInfo"
                          v-c-tooltip="'Click to Add new record!'"
                          >Add</CButton
                        >
                        <CButton
                          class="mr-2 btn-primary small"
                          style="height: 28px"
                          v-if="
                            !edit_non_medical_id &&
                            getMultiFormEdited['NonMedicalInfos']
                          "
                          @click="uploadInfo"
                          v-c-tooltip="'Click to Save record!'"
                          >Save</CButton
                        >
                        <CButton
                          class="mr-2 btn-primary small"
                          style="height: 28px"
                          @click="uploadInfo"
                          v-if="edit_non_medical_id"
                          v-c-tooltip="'Click to update the record!'"
                          >Update</CButton
                        >
                        <button
                          name="cancel"
                          v-if="
                            isNewNonMedicalQualification ||
                            edit_non_medical_id ||
                            getMultiFormEdited['NonMedicalInfos']
                          "
                          class="hand-icon action-button"
                          v-c-tooltip="'Cancel'"
                          @click="resetFields(), clearFields()"
                        >
                          <CIcon
                            name="cil-x-circle"
                            style="margin-right: 20px"
                          />
                        </button>
                      </div>
                    </CCol>
                  </CRow>
                </div>
              </div>
              <ValidationObserver
                ref="NonMedicalInfos"
                v-slot="{ handleSubmit }"
              >
                <form id="NonMedicalInfos" @submit.prevent="handleSubmit()">
                  <fieldset :disabled="isDisabled">
                    <CRow
                      v-if="
                        isNewNonMedicalQualification ||
                        edit_non_medical_id ||
                        getMultiFormEdited['NonMedicalInfos']
                      "
                    >
                      <CCol md="6">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="lbl_qualification_name"
                            class="required col-lg-12 col-md-12"
                            >Qualification/Course Name
                          </label>
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <Select
                                name="qualification_name"
                                :value="profile.qualification_name"
                                @input="handleChangeSelect"
                                :options="
                                  options && options['qualifications']
                                    ? options['qualifications']
                                    : []
                                "
                                :taggable="false"
                                :multiple="false"
                                :clearable="false"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="6" v-if="!other_qualification">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="lbl_others_name"
                            class="required col-lg-12 col-md-12"
                            >Qualification Subject</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <TextInput
                                name="others_name"
                                :value="profile.others_name"
                                @input="handleInput"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol v-if="other_qualification" md="6">
                        <CRow class="d-flex align-items-center mb-3">
                          <label
                            name="lbl_others_name"
                            class="required col-lg-12 col-md-12"
                            >Other Qualification ( Level / Subject )</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider
                              rules="required"
                              v-slot="{ errors }"
                            >
                              <TextInput
                                name="others_name"
                                :value="profile.others_name"
                                @input="handleInput"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                      <CCol md="10">
                        <CRow class="row mb-3">
                          <label
                            name="lbl_qual_month_year"
                            class="required col-lg-12 col-md-12"
                            >Date or Estimated Date of Award</label
                          >
                          <div class="col-lg-12 col-md-12 col-sm-12">
                            <ValidationProvider name="dob" v-slot="{ errors }">
                              <TextInput
                                hidden
                                name="dob"
                                :value="profile.dob"
                                :error="errors[0]"
                              />
                            </ValidationProvider>
                            <ValidationProvider
                              rules="date_validate|required"
                              v-slot="{ errors }"
                            >
                              <DateInput
                                :onlyMonthAndYear="true"
                                name="qual_month_year"
                                :value="profile.qual_month_year"
                                @change="handleDatePickerChange"
                                :error="errors[0]"
                                :futureYear="true"
                              />
                            </ValidationProvider>
                          </div>
                        </CRow>
                      </CCol>
                    </CRow>
                    <div v-if="candidateNonMedicalQuals" class="multi-records">
                      <div
                        class="top-border d-flex justify-content-between"
                        v-for="(data, index) in candidateNonMedicalQuals"
                        :key="index"
                      >
                        <div class="ml-2 mt-1 record">
                          <div class="widget-content-actions hide-large-screen">
                            <CButton
                              class="icon icon-edit mr-2"
                              v-if="data && data.id"
                              @click="editQualification(data)"
                            >
                            </CButton>
                            <CButton
                              class="icon mr-1"
                              v-if="data && data.id"
                              @click="deleteQualification(data)"
                            >
                              <i class="fas fa-trash-alt" />
                            </CButton>
                          </div>
                          <CRow
                            class="d-flex justify-content-between"
                            style="margin-left: 5px"
                          >
                            <div class="table col col-5">
                              <p class="head">Qualification</p>
                              <p class="data">{{ qualificationName(data) }}</p>
                            </div>
                            <div class="table col col-5">
                              <p class="head">Qualification Subject</p>
                              <p class="data">
                                {{ data.others_name || "--" }}
                              </p>
                            </div>
                            <div class="table col col-5">
                              <p class="head">Month/Year</p>
                              <p class="data">
                                {{ formatDate(data.month, data.year) }}
                                <span
                                  v-if="data.expired_message"
                                  class="data text-primary"
                                  :style="{ 'font-size': '14px' }"
                                  >{{ "(" + data.expired_message + ")" }}</span
                                >
                              </p>
                            </div>
                          </CRow>
                        </div>
                        <div class="hide-small-screen">
                          <div
                            class="widget-content-actions d-flex flex-column justify-content-top"
                          >
                            <CButton
                              class="icon icon-edit mb-2"
                              v-if="data && data.id"
                              @click="editQualification(data)"
                            >
                            </CButton>
                            <CButton
                              class="icon"
                              v-if="data && data.id"
                              @click="deleteQualification(data)"
                            >
                              <i class="fas fa-trash-alt" />
                            </CButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </fieldset>
                </form>
              </ValidationObserver>
            </div>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
    <Modal
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deletemodalCallBack"
      :closeOnBackdrop="false"
    />
  </div>
</template>

<script>
import m from "moment";
import ProfileSection from "./ProfileSection";
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/reusable/Modal";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
extend("required", { ...required, message: "This field is required" });
extend("date_validate", (value) => {
  if (m(value).isValid()) {
    return true;
  }
  return "Invalid date! Enter a valid date";
});
extend("QualifiedDate", {
  params: ["dob"],
  validate(value, { dob }) {
    let birth_date = new Date(dob);
    let input_date = new Date(value);
    let today_date = new Date();
    if (input_date > birth_date && input_date < today_date) {
      return true;
    }
  },
  message: "Given date should be between birthdate & today!",
});

export default {
  name: "NonMedicalQualificationInfoTalentFind",
  mixins: [ProfileSection],
  props: {
    candidateTypeId: {
      type: Number,
      default: null,
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      isDisabled: false,
      profile: {},
      NonMedicalInfo: {},
      nonMedicalInfoUpload: false,
      id: 0,
      non_medical_qual_id: null,
      isNewNonMedicalQualification: false,
      edit_non_medical_id: null,
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "NOTIFICATION",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      cardHasError: "",
      other_qualification: false,
      payload: {},
    };
  },
  computed: {
    ...mapGetters([
      "candidateProfile",
      "scoreFilteredByNonMedicalQualName",
      "getNonMedicalQual",
      "getCandidateNonMedicalQuals",
      "getNonMedicalQualforNonClinic",
      "uploaded",
    ]),
    options() {
      const non_medical_qual_type = this.qualTypeId.nonClinical; //Non Clinical Qualification Type ID
      return {
        qualifications: this.isNonClinicAdmin
          ? this.getNonMedicalQualforNonClinic
          : [
              ...this.getNonMedicalQual(non_medical_qual_type),
              { label: "Others - not listed" },
            ] || [],
        score: this.scoreFilteredByNonMedicalQualName || [],
      };
    },
    candidateNonMedicalQuals() {
      if (Object.keys(this.candidateProfile).length) {
        this.profile.dob = this.candidateProfile.dob;
      }
      const non_medical_qual_type = this.qualTypeId.nonClinical; //Non Clinical Qualification Type ID
      return (
        this.getCandidateNonMedicalQuals
          ?.map(
            ({
              id,
              non_medical_qual,
              month,
              year,
              others_name,
              others_score,
            }) => {
              return {
                id: id !== null ? id : "--",
                non_medical_qual_id: non_medical_qual
                  ? non_medical_qual?.id
                  : null,
                qual_type_id: non_medical_qual?.non_medical_qual_type
                  ? non_medical_qual?.non_medical_qual_type.id
                  : null,
                qual_type_name: non_medical_qual?.non_medical_qual_type
                  ? non_medical_qual?.non_medical_qual_type.type_name
                  : null,
                name: non_medical_qual ? non_medical_qual?.name : "--",
                score: non_medical_qual ? non_medical_qual?.score : "--",
                skill_level: non_medical_qual
                  ? non_medical_qual?.skill_level
                  : "--",
                month: month !== null ? month : null,
                year: year !== null ? year : null,
                others_name: others_name !== null ? others_name : "--",
                others_score: others_score !== null ? others_score : "--",
                expired_message: this.expiryCheck(
                  month,
                  year,
                  non_medical_qual
                ),
              };
            }
          )
          .filter(
            (v) =>
              v.qual_type_id === non_medical_qual_type ||
              v.qual_type_id == undefined
          ) || []
      );
    },
    cardError() {
      if (
        this.getMultiFormEdited.showError &&
        this.getMultiFormEdited.NonMedicalInfos
      ) {
        this.cardHasError = "Please Save or Delete";
        let el = this.$el.getElementsByClassName("cardError")[0];
        // el.scrollIntoView(true);
        el.scrollIntoView({ block: "center" });
        window.scrollBy(0, -200);
        return true;
      }
      return false;
    },
    isBusinessOrManQual() {
      return this.profile?.qualification_name?.qual_type_id == 2 ||
        this.profile?.qualification_name?.qual_type_id == 3
        ? true
        : false;
    },
    isNonClinicAdmin() {
      return this.candidateTypeId == 6 ? true : false;
    },
  },
  watch: {
    uploaded() {
      if (this.uploaded) {
        if (this.nonMedicalInfoUpload) {
          this.fetchCandidateNonMedicalQuals();
          this.nonMedicalInfoUpload = false;
        }
      }
    },
    getNonMedicalQual() {
      return this.options.qualifications.push({ label: "Others - not listed" });
    },
    "profile.qualification_name"() {
      if (this.profile?.qualification_name?.label === "Others - not listed") {
        this.other_qualification = true;
        if (this.profile?.score) this.profile.score = null;
      } else if (
        this.profile?.qualification_name?.label !== "Others - not listed"
      ) {
        this.other_qualification = false;
        if (this.profile?.others_name && !this.isBusinessOrManQual)
          this.profile.others_name = null;
        if (this.profile?.others_score) this.profile.others_score = null;
        let qualification_name;
        this.fetchScoreByNonMedicalQual(
          (qualification_name = this.profile?.qualification_name?.label
            .replace(/\ - /, "&")
            .split("&")[1])
        );
      }
    },
  },
  mounted() {
    if (!this.getNonMedicalQual?.length) {
      this.fetchNonMedicalQualNames();
    }
  },
  methods: {
    ...mapActions([
      "fetchCandidateNonMedicalQuals",
      "uploadCandidateNonMedicalInfo",
      "fetchScoreByNonMedicalQual",
      "fetchNonMedicalQualNames",
      "deleteNonMedicalInfo",
      "showToast",
      "updateNonMedicalInfo",
    ]),
    async uploadInfo() {
      const isValid = await this.$refs.NonMedicalInfos.validate();
      this.errors = Object.values(this.$refs.NonMedicalInfos.errors).flat();
      if (!isValid) {
        this.cardHasError = this.errors[0] || "please fill mandatory fields!";
        return;
      } else {
        if (this.profile.qualification_name.label !== "Others - not listed") {
          if (this.edit_non_medical_id !== null) {
            let _count = 0;
            let _index;
            for (var i = 0; i < this.getCandidateNonMedicalQuals.length; i++) {
              if (
                this.getCandidateNonMedicalQuals[i].non_medical_qual?.name ===
                this.profile?.qualification_name?.label
                  .replace(/\ - /, "&")
                  .split("&")[1]
              ) {
                _index = i;
                _count = _count + 1;
              }
            }
            if (
              _count >= 1 &&
              this.getCandidateNonMedicalQuals[_index].id !==
                this.edit_non_medical_id
            ) {
              this.cardHasError = "Qualification Already Exists!!";
            } else {
              this.saveNonMedicalInfo();
            }
          } else {
            if (
              this.getCandidateNonMedicalQuals?.length &&
              this.getCandidateNonMedicalQuals.findIndex(
                (e) =>
                  e.non_medical_qual?.name ===
                  this.profile?.qualification_name?.label
                    .replace(/\ - /, "&")
                    .split("&")[1]
              ) >= 0
            ) {
              this.cardHasError = "Qualification Already Exists!!";
            } else {
              this.saveNonMedicalInfo();
            }
          }
        }
        if (this.profile.qualification_name.label === "Others - not listed") {
          if (this.edit_non_medical_id !== null) {
            let _count = 0;
            let _index;
            for (var i = 0; i < this.getCandidateNonMedicalQuals.length; i++) {
              if (
                this.getCandidateNonMedicalQuals[
                  i
                ].others_name?.toLowerCase() ===
                this.profile?.others_name?.toLowerCase()
              ) {
                _index = i;
                _count = _count + 1;
              }
            }
            if (
              _count >= 1 &&
              this.getCandidateNonMedicalQuals[_index].id !==
                this.edit_non_medical_id
            ) {
              this.cardHasError = "Qualification Already Exists!!";
            } else {
              this.saveNonMedicalInfo();
            }
          } else {
            if (
              this.getCandidateNonMedicalQuals?.length &&
              this.getCandidateNonMedicalQuals.findIndex(
                (e) =>
                  e.others_name?.toLowerCase() ==
                  this.profile?.others_name?.toLowerCase()
              ) >= 0
            ) {
              this.cardHasError = "Qualification Already Exists!!";
            } else {
              this.saveNonMedicalInfo();
            }
          }
        }
      }
    },
    expiryCheck(month, year, non_medical_qual) {
      let today = new Date();
      let expires_in = non_medical_qual?.expires_in
        ? non_medical_qual?.expires_in
        : null;
      if (month && year && expires_in) {
        year += expires_in;
        let expiration_date = new Date(
          `${month != 12 ? year : year + 1}-${month != 12 ? month + 1 : 1}-01`
        );
        if (expiration_date <= today) {
          let expired_message = non_medical_qual?.expired_message;
          return expired_message;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    saveNonMedicalInfo() {
      this.nonMedicalInfoUpload = true;
      this.NonMedicalInfo = {
        candidate_uid: this.candidateProfile?.candidate_uid
          ? this.candidateProfile?.candidate_uid
          : null,
        non_medical_qual_id: this.profile?.score?.code
          ? this.profile?.score?.code
          : this.isBusinessOrManQual
          ? this.profile?.qualification_name?.non_medical_qual_id
          : null,
        year:
          this.profile?.qual_month_year !== null
            ? m(this.profile?.qual_month_year).year()
            : null,
        month:
          this.profile?.qual_month_year !== null
            ? m(this.profile?.qual_month_year).month() + 1
            : null,
        others_name:
          this.profile?.qualification_name?.label == "Others - not listed" ||
          this.isBusinessOrManQual
            ? this.profile?.others_name != ""
              ? this.profile?.others_name
              : null
            : null,
        others_score:
          this.profile.qualification_name?.label == "Others - not listed"
            ? this.profile?.others_score
            : null,
      };
      let appendAction;
      if (this.NonMedicalInfo && this.edit_non_medical_id == null) {
        appendAction = this.uploadCandidateNonMedicalInfo(this.NonMedicalInfo);
      } else {
        let payload = {
          non_medical_qual_id: this.NonMedicalInfo.non_medical_qual_id || null,
          candidate_uid: this.candidateProfile?.candidate_uid,
          year: this.NonMedicalInfo.year,
          month: this.NonMedicalInfo.month,
          others_name: this.NonMedicalInfo.others_name,
          others_score: this.NonMedicalInfo.others_score,
        };
        let candidate_non_medical_qual_id = this.edit_non_medical_id || null;
        appendAction = this.updateNonMedicalInfo({
          candidate_non_medical_qual_id,
          payload,
        });
      }
      Promise.all([appendAction]).then((res) => {
        this.edit_non_medical_id = null;
        this.resetFields();
        this.clearFields();
      });
    },
    showNonMedicalQualificationInfo() {
      this.isNewNonMedicalQualification = true;
    },
    resetFields() {
      this.profile = {};
      this.edit_non_medical_id = null;
      this.$emit("tooltipHide");
      this.isNewNonMedicalQualification = false;
    },
    clearFields() {
      this.$nextTick(() => {
        this.$refs?.NonMedicalInfos.reset();
        this.$refs?.NonMedicalInfos.errors.clear;
      });
      this.cardHasError = "";
      this.multiFormEdited({ competencyInfo: false, NonMedicalInfos: false });
      this.multiFormEdited({ showError: false });
      this.isNewNonMedicalQualification = false;
    },
    deleteQualification(data) {
      this.$emit("tooltipHide");
      this.deleteConfirmModal.modalContent = `Do you want to delete Qualification - ${
        data.name !== "--" ? data.name : data.others_name
      } ?`;
      this.id = data.id;
      this.deleteConfirmModal.isShowPopup = true;
    },
    editQualification(data) {
      let el = this.$el.getElementsByClassName("NonMedicalInformation")[0];
      el.scrollIntoView(true);
      window.scrollBy(0, -200);
      this.profile = {
        ...this.profile,
        qualification_name: {
          code: data?.qual_type_id ? data.qual_type_id : "Others",
          label:
            data.name !== "--" && data.qual_type_name
              ? data.qual_type_name + " - " + data.name
              : "Others - not listed",
          qual_type_id: data?.qual_type_id,
          non_medical_qual_id: data?.non_medical_qual_id,
        },
        score: {
          label: data.score !== "--" ? data.score : null,
          code: data.non_medical_qual_id,
        },
        qual_month_year:
          data.year && data.month != "--"
            ? data.month < 10
              ? `${data.year}-0${data.month}`
              : `${data.year}-${data.month}`
            : null,
        others_name: data.others_name !== "--" ? data.others_name : null,
        others_score: data.others_score !== "--" ? data.others_score : null,
      };
      this.edit_non_medical_id = data.id;
      this.non_medical_qual_id = data.non_medical_qual_id;
    },
    deletemodalCallBack(action) {
      if (action === "Confirm") {
        this.deleteNonMedicalInfo(this.id);
      }
      this.id = "";
      this.resetFields();
      this.clearFields();
      this.deleteConfirmModal.isShowPopup = false;
    },
    formatDate(month, year) {
      if (month && year) {
        let date = month ? new Date(`${year},${month},01`) : "--";
        return m(date).format("MMM-YYYY");
      }
      return "--";
    },
    qualificationName(data) {
      if (data.name !== null)
        if (data.qual_type_id == 2 || data.qual_type_id == 3) {
          let qual_name = data.name + " - " + data.others_name;
          return qual_name;
        }
      return data.name !== "--"
        ? data.name
        : `Other${data.others_name ? " - " + data.others_name : ""}`;
      return "--";
    },
    qualificationScore(data) {
      if (data.name !== null)
        return data.score !== "N/A"
          ? `${data.score ? data.score : "--"}`
          : "--";
      return "--";
    },
  },
};
</script>

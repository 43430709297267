<template>
  <div class="resume-upload candidate-profile">
    <CCard class="has-loading-overlay">
      <CCardBody>
        <h5 class="main-title pb-3">Upload Documents</h5>
        <ValidationObserver v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit()">
            <CRow>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label name="lbl_document_type" class="col-lg-12 col-md-12"
                    >Document Type</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <Select
                      name="document_type"
                      :value="uploadData.document_type || `CV / Resume`"
                      @input="handleChangeSelect"
                      :options="
                        options && options['document_type']
                          ? options['document_type']
                          : []
                      "
                      :taggable="false"
                      :multiple="false"
                      :clearable="false"
                    />
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow class="row mb-3">
                  <label name="lbl_upload-document" class="col-lg-12 col-md-12"
                    >Upload Document</label
                  >
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <input
                      name="upload-document"
                      type="file"
                      ref="fileref"
                      :accept="arrayToComma(fileType)"
                      @change="selectedFile"
                    />
                  </div>
                  <div v-if="isImageSelected">
                    <uploadDocument
                      :documentType="uploadData.document_type"
                      :isProfilePic="false"
                      :fileData="fileData"
                      @closeModal="closeModal"
                      v-on="$listeners"
                    />
                  </div>
                </CRow>
              </CCol>
            </CRow>

            <div v-if="data" class="multi-records">
              <div
                class="top-border d-flex"
                style="justify-content: space-between"
                v-if="latestResume"
              >
                <div class="ml-2 mt-1 record" style="min-width: 80%">
                  <CRow
                    class="d-flex"
                    style="justify-content: space-around; margin-left: 2px"
                  >
                    <div class="table col col-4">
                      <p class="head">Document Type</p>
                      <p class="data">
                        {{ latestResume.label }}
                        <span class="data text-primary h6 f-13">{{
                          `(Latest)`
                        }}</span>
                      </p>
                    </div>
                    <div class="table col col-4">
                      <p class="head">Document Name</p>
                      <p class="data">{{ latestResume.name }}</p>
                    </div>
                    <div class="table col col-4">
                      <p class="head">Uploaded Date</p>
                      <p class="data">{{ latestResume.updatedDate }}</p>
                    </div>
                    <div class="text-justify table col col-12">
                      <p class="head">Description</p>
                      <a
                        class="hand-icon btn pr-3 p-0"
                        @click="editInfo(latestResume)"
                      >
                        <em class="fas fa-pencil-alt"></em>
                      </a>
                      {{ latestResume.comments ? latestResume.comments : "--" }}
                    </div>
                  </CRow>
                </div>

                <div
                  class="widget-content-actions d-flex flex-column justify-content-center"
                >
                  <button
                    type="button"
                    :disabled="
                      disabledButton.includes(latestResume.document_id)
                    "
                    class="btn icon hand-icon mb-1"
                    v-if="latestResume && latestResume.link"
                    @click="
                      fileDownload(latestResume, latestResume.document_id)
                    "
                  >
                    <em class="icon fas fa-download"></em>
                  </button>

                  <button
                    type="button"
                    class="btn icon hand-icon mb-1"
                    v-if="latestResume && latestResume.link"
                    @click="preview(latestResume)"
                  >
                    <em class="fas fa-eye"></em>
                  </button>

                  <button
                    class="btn icon hand-icon"
                    v-if="latestResume && latestResume.link"
                    @click="deleteDocuments(latestResume)"
                  >
                    <em class="fas fa-trash"></em>
                  </button>
                </div>
              </div>
              <p
                class="text-center text-primary h6 m-0 p-0 f-13"
                v-if="previousResume && previousResume.length"
              >
                Previous version(s) of CV/Resume
              </p>
              <div
                class="top-border d-flex"
                style="justify-content: space-between"
                v-for="data in previousResume"
                :key="data.document_id"
              >
                <div class="ml-2 mt-1 record" style="min-width: 80%">
                  <CRow
                    class="d-flex"
                    style="justify-content: space-around; margin-left: 2px"
                  >
                    <div class="table col col-4">
                      <p class="head">Document Type</p>
                      <p class="data">
                        {{ data.label }}
                      </p>
                    </div>
                    <div class="table col col-4">
                      <p class="head">Document Name</p>
                      <p class="data">{{ data.name }}</p>
                    </div>
                    <div class="table col col-4">
                      <p class="head">Uploaded Date</p>
                      <p class="data">{{ data.updatedDate }}</p>
                    </div>
                    <div class="text-justify table col col-12">
                      <p class="head">Description</p>
                      <a class="hand-icon btn pr-3 p-0" @click="editInfo(data)">
                        <em class="fas fa-pencil-alt"></em>
                      </a>
                      {{ data.comments ? data.comments : "--" }}
                    </div>
                  </CRow>
                </div>

                <div
                  class="widget-content-actions d-flex flex-column justify-content-center"
                >
                  <button
                    type="button"
                    :disabled="disabledButton.includes(data.document_id)"
                    class="btn icon hand-icon mb-1"
                    v-if="data && data.link"
                    @click="fileDownload(data, data.document_id)"
                  >
                    <em class="icon fas fa-download"></em>
                  </button>

                  <button
                    type="button"
                    class="btn icon hand-icon mb-1"
                    v-if="data && data.link"
                    @click="preview(data)"
                  >
                    <em class="fas fa-eye"></em>
                  </button>

                  <button
                    class="btn icon hand-icon"
                    v-if="data && data.link"
                    @click="deleteDocuments(data)"
                  >
                    <em class="fas fa-trash"></em>
                  </button>
                </div>
              </div>
              <div
                class="top-border d-flex"
                style="justify-content: space-between"
                v-for="data in otherDocuments"
                :key="data.document_id"
              >
                <div class="ml-2 mt-1 record" style="min-width: 80%">
                  <CRow
                    class="d-flex"
                    style="justify-content: space-around; margin-left: 2px"
                  >
                    <div class="table col col-4">
                      <p class="head">Document Type</p>
                      <p class="data">
                        {{ data.label }}
                      </p>
                    </div>
                    <div class="table col col-4">
                      <p class="head">Document Name</p>
                      <p class="data">{{ data.name }}</p>
                    </div>
                    <div class="table col col-4">
                      <p class="head">Uploaded Date</p>
                      <p class="data">{{ data.updatedDate }}</p>
                    </div>
                    <div class="text-justify table col col-12">
                      <p class="head">Description</p>
                      <a class="hand-icon btn pr-3 p-0" @click="editInfo(data)">
                        <em class="fas fa-pencil-alt"></em>
                      </a>
                      {{ data.comments ? data.comments : "--" }}
                    </div>
                  </CRow>
                </div>

                <div
                  class="widget-content-actions d-flex flex-column justify-content-center"
                >
                  <button
                    type="button"
                    :disabled="disabledButton.includes(data.document_id)"
                    class="btn icon hand-icon mb-1"
                    v-if="data && data.link"
                    @click="fileDownload(data, data.document_id)"
                  >
                    <em class="icon fas fa-download"></em>
                  </button>

                  <button
                    type="button"
                    class="btn icon hand-icon mb-1"
                    v-if="data && data.link"
                    @click="preview(data)"
                  >
                    <em class="fas fa-eye"></em>
                  </button>

                  <button
                    class="btn icon hand-icon"
                    v-if="data && data.link"
                    @click="deleteDocuments(data)"
                  >
                    <em class="fas fa-trash"></em>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </CCardBody>
    </CCard>
    <CToaster v-if="isFileError" :autohide="3000" position="top-right">
      <CToast :show="true" class="bg-danger text-white"
        >File too Big, please select a file less than 2mb</CToast
      >
    </CToaster>
    <CToaster v-if="isFileTpeValid" :autohide="5000" position="top-right">
      <CToast :show="true" class="bg-danger text-white"
        >Allowed File Type {{ FileTypeError }}</CToast
      >
    </CToaster>
    <PreviewModal
      :modalTitle="confirmationModal.modalTitle"
      :modalColor="confirmationModal.modalColor"
      :isShowPopup="confirmationModal.isShowPopup"
      :buttons="confirmationModal.buttons"
      :modalCallBack="modalCallBack"
      :isPreview="confirmationModal.isPreview"
      :iframe="iframe"
      :size="confirmationModal.size"
      :closeOnBackdrop="confirmationModal.closeOnBackdrop"
    />
    <Modal
      :modalTitle="deleteConfirmModal.modalTitle"
      :modalColor="deleteConfirmModal.modalColor"
      :modalContent="deleteConfirmModal.modalContent"
      :isShowPopup="deleteConfirmModal.isShowPopup"
      :buttons="deleteConfirmModal.buttons"
      :modalCallBack="deletemodalCallBack"
      :closeOnBackdrop="false"
    />
    <DocumentInfoEdit
      :documentInfo="EditModal.documentInfo"
      :isShowPopup="EditModal.isShowPopup"
      @modalCallBack="documentInfoModalCallBack"
      v-if="EditModal.isShowPopup"
    />
    <qualification-mapping-modal
      :isShowPopup="linkDocument.isShowPopup"
      :document_type_id="linkDocument.document_type_id"
      :candidate_document_id="linkDocument.candidate_document_id"
      :label="linkDocument.label"
      :modalTitle="linkDocument.modalTitle"
      :modalContent="linkDocument.modalContent"
    />
  </div>
</template>

<script>
import Vue from "vue";
import ProfileSection from "./ProfileSection";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import UploadDocument from "@/containers/CandidateProfile/UploadDocument";
import PreviewModal from "@/components/reusable/PreviewModal";
import pdf from "vue-pdf";
import Modal from "@/components/reusable/Modal";
import DocumentInfoEdit from "@/containers/CandidateDetailsTalentFind/EditForm/DocumentInfoEdit";
import QualificationMappingModal from "./QualificationMappingModal.vue";
import { getScope, Role, appendAccessToken } from "@/helpers/helper";
import { BASE_URL } from "@/service_urls";

export default {
  mixins: [ProfileSection],
  components: {
    UploadDocument,
    PreviewModal,
    Modal,
    DocumentInfoEdit,
    QualificationMappingModal,
  },
  data() {
    return {
      isImageSelected: false,
      fileData: {},
      uploadData: {},
      payload: {},
      photo: {},
      data: {},
      isCroped: false,
      isFileError: false,
      isFileTpeValid: false,
      ext: null,
      disabledButton: [],
      FileTypeError: null,
      iframe: {
        src: "",
        loaded: false,
        isImg: false,
        pdf: {
          ispdf: false,
          src: "",
          numPages: undefined,
          addContentClasses: "",
        },
      },
      confirmationModal: {
        modalColor: "primary",
        modalTitle: "Document Preview",
        isShowPopup: false,
        buttons: ["Ok"],
        isPreview: false,
        size: "lg",
        closeOnBackdrop: false,
      },
      deleteConfirmModal: {
        modalColor: "danger",
        modalTitle: "Notification",
        modalContent: "",
        isShowPopup: false,
        buttons: ["Cancel", "Confirm"],
      },
      candidate_document: {},
      EditModal: {
        isShowPopup: false,
        documentInfo: null,
      },
      linkDocument: {
        label: "",
        modalTitle: "",
        modalContent: "",
        candidate_document_id: null,
        isShowPopup: false,
        document_type_id: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      "uploaded",
      "documentType",
      "getUploadedDocument",
      "candidateProfile",
      "documentTypeExcPhoto",
      "lastUploadedDocs",
      "isCandidateRoleFromAccessToken",
    ]),
    documentTypeExcPhotoCustomOrder() {
      const sortOrder = [1];
      return (
        (this.documentTypeExcPhoto &&
          this.documentTypeExcPhoto.length &&
          this.documentTypeExcPhoto.sort(
            (a, b) => sortOrder.indexOf(b.code) - sortOrder.indexOf(a.code)
          )) ||
        []
      );
    },
    options() {
      return {
        document_type:
          this.documentTypeExcPhotoCustomOrder
            .filter(({ code }) => ![23, 24, 26].includes(code))
            .map((data) => data) || [],
      };
    },
    document() {
      let excludeDocTypes = [23, 24, 25, 26, 27, 28];
      if (this.isCandidateRoleFromAccessToken) excludeDocTypes.push(20);
      return (
        (this.getUploadedDocument?.length &&
          this.getUploadedDocument
            .filter((docs) => !excludeDocTypes.includes(docs.document_type_id))
            .map(
              ({
                document_type_id,
                uuid_link_to_doc,
                document_name,
                document_type,
                created_on,
                document_ext,
                candidate_document_uuid,
                comments,
                version_no,
              }) => {
                return {
                  document_type_id: document_type_id,
                  link: uuid_link_to_doc,
                  label: document_type.comments,
                  name: document_name,
                  updatedDate: moment(created_on).format("DD-MM-YYYY"),
                  document_ext: document_ext?.document_ext,
                  document_id: candidate_document_uuid,
                  comments: comments,
                  version_no: version_no,
                };
              }
            )
            .sort((a, b) => b.version_no - a.version_no)
            .sort((a, b) => a.document_type_id - b.document_type_id)) ||
        []
      );
    },
    resumeDocument() {
      return this.document?.filter((fil) => fil.document_type_id === 1);
    },
    latestResume() {
      return _.maxBy(this.resumeDocument, "version_no");
    },
    previousResume() {
      return _.filter(
        this.resumeDocument,
        (doc) => this.latestResume?.document_id != doc.document_id
      );
    },
    otherDocuments() {
      return this.document?.filter((fil) => fil.document_type_id != 1);
    },
    fileType() {
      if (this.documentType.length) {
        if (
          this.uploadData.document_type &&
          this.uploadData.document_type.code
        ) {
          let fileExt = this.documentType
            .filter(({ code }) => code === this.uploadData.document_type.code)
            .map((d) => d.allowed_extensions)[0];
          fileExt = fileExt.map((d) => d.toLowerCase());
          return fileExt;
        } else {
          let fileExt = this.documentType
            .filter(({ code }) => code === 1)
            .map((d) => d.allowed_extensions)[0];
          fileExt = fileExt.map((d) => d.toLowerCase());
          return fileExt;
        }
      }
      return [];
    },
  },
  watch: {
    uploaded() {
      if (this.uploaded) {
        this.$refs.fileref.value = "";
        this.uploadData = {};
        this.fileData = {};
        this.isImageSelected = false;
        this.linkDocument.document_type_id =
          this.lastUploadedDocs?.document_type_id;
        this.linkDocument.label = this.lastUploadedDocs?.document_name;
        this.linkDocument.candidate_document_id =
          this.lastUploadedDocs?.candidate_document_id;
        if (this.lastUploadedDocs?.document_type_id == 9) {
          this.linkDocument.modalTitle = "License Mapping";
          this.linkDocument.modalContent =
            "Select the License to be mapped with the Document:";
          this.linkDocument.isShowPopup = true;
        } else if (this.lastUploadedDocs?.document_type_id == 12) {
          this.linkDocument.modalTitle = "Qualification Mapping";
          this.linkDocument.modalContent =
            "Select the Qualification to be mapped with the Document:";
          this.linkDocument.isShowPopup = true;
        }
      }
    },
  },
  mounted() {
    this.initFetchOptions();
  },
  methods: {
    ...mapActions([
      "fetchUploadedDocument",
      "downloadDocument",
      "initFetchOptions",
      "showToast",
      "deleteDocument",
      "updateDocument",
      "fetchQualification",
      "fetchLicense",
    ]),
    selectedFile(event) {
      this.isFileError = false;
      this.isFileTpeValid = false;
      const size = event.target.files[0].size;
      if (Math.round(size / (1024 * 1024)) <= 2) {
        const file = event.target.files[0];
        const fileExt = this.getExtension(file.name);
        const fileTypeString = this.fileType;
        if (fileTypeString.includes(fileExt)) {
          if (file.type.startsWith("image/")) {
            this.isImageSelected = true;
            this.fileData = file;
          } else {
            this.handleFileUploads(file);
          }
        } else {
          this.isFileTpeValid = true;
          this.FileTypeError = this.arrayToComma(fileTypeString);
          this.$refs.fileref.value = "";
          this.showToaster();
        }
      } else {
        this.$refs.fileref.value = "";
        this.isFileError = true;
        this.showToaster();
      }
    },
    handleChangeSelect(name, value) {
      Vue.set(this.uploadData, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
    },
    handleFileUploads(event) {
      this.payload = {
        ...this.payload,
        name: ["file"],
        value: event,
        isPhotoUpload: false,
      };
      this.$emit("upload", this.payload);
    },

    fileDownload(data, index) {
      this.disabledButton.push(index);
      let { link, name: document_name } = data;
      let url = link.replace("/api/v1/", "");
      url=appendAccessToken(url)
      this.downloadDocument({ url, document_name }).finally(() => {
        this.disabledButton.shift();
      });
      return false;
    },
    showPreview(data) {
      const { link } = data;
        //  const _baseUrl = BASE_URL;
        //  const _base_domain = _baseUrl.substring(0, _baseUrl.indexOf("/api"));
           const _base_domain = "https://" + window.location.host;
      return appendAccessToken(`${_base_domain}${link}`);
    },
    preview(data) {
      data.link_to_document = this.showPreview(data);
      if (
        data.document_ext === "PNG" ||
        data.document_ext === "JPG" ||
        data.document_ext === "JPEG" ||
        data.document_ext === "GIF"
      ) {
        this.iframe.loaded = false;
        this.iframe.ispdf = false;
        this.iframe.isImg = true;
        this.iframe.src = data.link_to_document;
        this.confirmationModal.isPreview = true;
        this.confirmationModal.isShowPopup = true;
      } else if (data.document_ext === "PDF") {
        this.iframe.isImg = false;
        this.iframe.loaded = false;
        this.iframe.pdf.ispdf = true;
        this.iframe.pdf.src = pdf.createLoadingTask(data.link_to_document);
        setTimeout(() => {
          this.iframe.pdf.src.promise.then((pdf) => {
            this.iframe.pdf.numPages = pdf.numPages;
          });
        }, 100);
        this.confirmationModal.isPreview = true;
        this.confirmationModal.isShowPopup = true;
      } else if (
        data.document_ext === "TXT" ||
        data.document_ext === "EPS" ||
        data.document_ext === "TIF" ||
        data.document_ext === "TIFF" ||
        data.document_ext === "RAW"
      ) {
        this.showToast({
          class: "bg-danger text-white",
          message: "The selected file type is not available for Preview.",
        });
        this.iframe.src = "";
        this.iframe.loaded = false;
        this.iframe.isImg = false;
        this.iframe.pdf.ispdf = false;
        this.confirmationModal.isPreview = false;
        this.confirmationModal.isShowPopup = false;
      } else {
        this.iframe.isImg = false;
        this.iframe.ispdf = false;
        this.iframe.loaded = true;
        this.iframe.src =
          "https://view.officeapps.live.com/op/embed.aspx?src=" +
          data.link_to_document;
        this.confirmationModal.isPreview = true;
        this.confirmationModal.isShowPopup = true;
      }
    },
    modalCallBack(action) {
      this.confirmationModal.isPreview = false;
      this.confirmationModal.isShowPopup = false;
      this.iframe.src = "";
      this.iframe.loaded = false;
      this.iframe.isImg = false;
      this.iframe.pdf.ispdf = false;
    },
    closeModal() {
      this.$refs.fileref.value = "";
      this.uploadData = {};
      this.fileData = {};
      this.isImageSelected = false;
    },
    getExtension(filename) {
      let parts = filename.split(".");
      return parts[parts.length - 1];
    },
    arrayToComma(d) {
      return d?.map((v) => "." + v).join(", ");
    },
    showToaster() {
      setTimeout(() => {
        this.isFileError = false;
        this.isFileTpeValid = false;
      }, 5000);
    },
    deleteDocuments(data) {
      this.deleteConfirmModal.modalContent = `Do you want to delete this document - ${data.name} ?`;
      this.candidate_document = data;
      this.deleteConfirmModal.isShowPopup = true;
    },
    deletemodalCallBack(action) {
      if (action === "Confirm") {
        let document = this.candidate_document;
        this.deleteDocument(document.document_id).then((res) => {
          if (document.document_type_id == 9) this.fetchLicense();
          if (document.document_type_id == 12) this.fetchQualification();
        });
      }
      this.candidate_document_id = {};
      this.deleteConfirmModal.isShowPopup = false;
    },
    editInfo(data) {
      this.EditModal.isShowPopup = true;
      this.EditModal.documentInfo = data;
      return false;
    },
    documentInfoModalCallBack(action, data) {
      this.EditModal.isShowPopup = false;
      if (action == true) {
        let payload = {
          candidate_document_id: this.EditModal.documentInfo.document_id,
          comments: data,
        };
        this.updateDocument(payload);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.my-3 {
  .preview {
    position: relative;
    width: 200px !important;
    height: 200px;
    overflow: hidden;
    border-radius: 50%;
    background-color: $white;
    border: $white;
  }
}

.hand-icon {
  cursor: pointer;
}

.action-button {
  width: 14px;
  height: 14px;
  border: 0;
  background: transparent;
  padding: 0;
  outline: none;
}

.documentScrolling {
  overflow-x: auto;
}
</style>
